<template>
  <b-overlay :show="showOverlay">
    <validation-observer ref="createUser" tag="div">
      <b-card>
        <!-- Media -->
        <b-form-group
            class="my-2"
            label="Avatar Image"
        >
          <MediaHandler ref="mediaHandler" @sendData="setFiles"></MediaHandler>

        </b-form-group>
        <!-- User Info: Input Fields -->
        <b-form v-if="data">
          <b-row>

            <!-- Field: Username -->
            <b-col
                cols="12"
                md="4"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-group
                    label="Name"
                >

                  <b-form-input
                      v-model="data.firstName"
                      :state="errors.length > 0 ? false:null"

                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field:  Name -->
            <b-col
                cols="12"
                md="4"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-group
                    label="Last Name"
                >
                  <b-form-input
                      id="name"
                      v-model="data.lastName"
                      :state="errors.length > 0 ? false:null"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field:  familyName -->
            <b-col
                cols="12"
                md="4"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-group
                    label="Email"
                >
                  <b-form-input
                      id="familyName"
                      v-model="data.email"

                      :state="errors.length > 0 ? false:null"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Email -->
            <b-col
                cols="12"
                md="4"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-group
                    label="User Name"
                >
                  <b-form-input
                      id="email"
                      v-model="data.userName"
                      :state="errors.length > 0 ? false:null"
                      type="email"

                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Status -->
            <b-col
                cols="12"
                md="4"
            >
              <b-form-group
                  label="User Status"
              >
                <v-select
                    v-model="data.userStatus"
                    :clearable="false"
                    :options="userStatuses"
                    :reduce="name => name.value"
                    input-id="user-status"
                    label="name"
                />
              </b-form-group>
            </b-col>
            <b-col
                cols="12"
                md="4"
            >
              <b-form-group
                  label="User Type"
              >
                <v-select
                    v-model="data.userType"
                    :clearable="false"
                    :options="userTypes"
                    :reduce="name => name.value"
                    input-id="user-status"
                    label="name"
                />
              </b-form-group>
            </b-col>

            <!-- Field: password -->
            <b-col
                cols="12"
                md="4"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-group
                    label="User password"
                >
                  <b-form-input
                      id="password"
                      v-model="data.password"
                      :state="errors.length > 0 ? false:null"
                      class=""

                  />
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>
        </b-form>

        <!--      &lt;!&ndash; Action Buttons &ndash;&gt;-->
        <!--      <b-button-->
        <!--          variant="primary"-->
        <!--          class="mb-1 mb-sm-0 mr-0 mr-sm-1"-->
        <!--          :block="$store.getters['app/currentBreakPoint'] === 'xs'"-->
        <!--          @click="submitEdit"-->
        <!--      >-->
        <!--        Submit edit-->
        <!--      </b-button>-->
      </b-card>
      <b-card>
        <strong>KYC Information</strong>
        <!-- User Info: Input Fields -->
        <b-form v-if="data" class="my-1">
          <b-row>
            <!-- Field: Username -->
            <b-col
                cols="12"
                md="4"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-group
                    label="Telegram"
                >
                  <b-form-input
                      v-model="data.telegram"
                      :state="errors.length > 0 ? false:null"

                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field:  Name -->
            <b-col
                cols="12"
                md="4"
            >

              <b-form-group
                  label="Discord"
              >
                <b-form-input
                    id="name"
                    v-model="data.discord"

                />
              </b-form-group>
            </b-col>

            <!-- Field:  familyName -->
            <b-col
                cols="12"
                md="6"
            >
              <b-form-group
                  label="Experience"
              >
                <b-form-input
                    id="familyName"
                    v-model="data.userExpriences"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <!-- Action Buttons -->
        <b-button

            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            variant="primary"
            @click="createUser"
        >
          Create
        </b-button>
      </b-card>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BMedia,
  BOverlay,
  BRow,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Helper from '@/libs/Helper'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { CreateUserByAdmin } from '@/libs/Api/Users'

export default {
  data() {
    return {
      required,
      userStatuses: [
        {
          name: 'InActive',
          value: 'InActive'
        },
        {
          name: 'Active',
          value: 'Active'
        },
        {
          name: 'PreRegister (KYC)',
          value: 'PreRegister'
        },
        {}

      ],
      userTypes: [
        {
          name: 'Customer',
          value: 'DefaultUser'
        },
        {
          name: 'Admin',
          value: 'AdminUser'
        },
        {
          name: 'Super Admin',
          value: 'SuperAdmin'
        },
        {
          name: 'Booster',
          value: 'BoosterUser'
        },
        {
          name: 'Currency Seller',
          value: 'CurrencySellerUser'
        },
        {
          name: 'Currency Farmer',
          value: 'CurrencyFarmerUser'
        },
        {
          name: 'Influencer',
          value: 'InfluencerUser'
        },
        {
          name: 'Author',
          value: 'AuthorUser'
        },

      ],
      data: {
        telegram: '',
        discord: '',
        email: '',
        password: '',
        userName: '',
        firstName: '',
        lastName: '',
        userGameServices: [],
        userExpriences: '',
        isReadRules: true,
        phoneNumber: '',
        avatarImage: null,
        userType: '',
        userStatus: '',
      },
      showOverlay: false,
    }
  },
  computed: {},
  methods: {
    setFiles(file) {
      if (file) {
        this.data.avatarImage = file
      }
    },
    async createUser() {
      let _this = this
      Helper.validateForm(_this, 'createUser', async () => {
        _this.showOverlay = true
        let createUserByAdmin = new CreateUserByAdmin(_this)
        _this.$refs.mediaHandler.sendFiles()
        let myForm = Helper.makeFormData(_this.data)
        createUserByAdmin.setParams(myForm)
        await createUserByAdmin.sendFormData(function (content) {
          _this.$toast({
            component: ToastificationContent,
            props: {
              title: `success`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `user successfully created`,
            },
          })

          _this.showOverlay = false
          _this.$router.push('/apps/users/list')
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })
    },
    submitEdit() {
      this.$emit('submitEdit')
    },
  },
  components: {
    MediaHandler,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BOverlay,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

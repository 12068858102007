var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showOverlay}},[_c('validation-observer',{ref:"createUser",attrs:{"tag":"div"}},[_c('b-card',[_c('b-form-group',{staticClass:"my-2",attrs:{"label":"Avatar Image"}},[_c('MediaHandler',{ref:"mediaHandler",on:{"sendData":_vm.setFiles}})],1),(_vm.data)?_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.data.firstName),callback:function ($$v) {_vm.$set(_vm.data, "firstName", $$v)},expression:"data.firstName"}})],1)]}}],null,false,3279333304)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Last Name"}},[_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null},model:{value:(_vm.data.lastName),callback:function ($$v) {_vm.$set(_vm.data, "lastName", $$v)},expression:"data.lastName"}})],1)]}}],null,false,1534648126)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email"}},[_c('b-form-input',{attrs:{"id":"familyName","state":errors.length > 0 ? false:null},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})],1)]}}],null,false,666781960)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"User Name"}},[_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"type":"email"},model:{value:(_vm.data.userName),callback:function ($$v) {_vm.$set(_vm.data, "userName", $$v)},expression:"data.userName"}})],1)]}}],null,false,3418356215)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"User Status"}},[_c('v-select',{attrs:{"clearable":false,"options":_vm.userStatuses,"reduce":function (name) { return name.value; },"input-id":"user-status","label":"name"},model:{value:(_vm.data.userStatus),callback:function ($$v) {_vm.$set(_vm.data, "userStatus", $$v)},expression:"data.userStatus"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"User Type"}},[_c('v-select',{attrs:{"clearable":false,"options":_vm.userTypes,"reduce":function (name) { return name.value; },"input-id":"user-status","label":"name"},model:{value:(_vm.data.userType),callback:function ($$v) {_vm.$set(_vm.data, "userType", $$v)},expression:"data.userType"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"User password"}},[_c('b-form-input',{attrs:{"id":"password","state":errors.length > 0 ? false:null},model:{value:(_vm.data.password),callback:function ($$v) {_vm.$set(_vm.data, "password", $$v)},expression:"data.password"}})],1)]}}],null,false,4165465015)})],1)],1)],1):_vm._e()],1),_c('b-card',[_c('strong',[_vm._v("KYC Information")]),(_vm.data)?_c('b-form',{staticClass:"my-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Telegram"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.data.telegram),callback:function ($$v) {_vm.$set(_vm.data, "telegram", $$v)},expression:"data.telegram"}})],1)]}}],null,false,3971699618)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Discord"}},[_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.data.discord),callback:function ($$v) {_vm.$set(_vm.data, "discord", $$v)},expression:"data.discord"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Experience"}},[_c('b-form-input',{attrs:{"id":"familyName"},model:{value:(_vm.data.userExpriences),callback:function ($$v) {_vm.$set(_vm.data, "userExpriences", $$v)},expression:"data.userExpriences"}})],1)],1)],1)],1):_vm._e(),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":_vm.createUser}},[_vm._v(" Create ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
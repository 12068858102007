import WebServiceRequest from '../Api/WebServiceRequest'

class UsersChangeUserRoleRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
  }

  setParams(data) {
    super.setTag('Users/ChangeUserRole?userId=' + data.userId + '&UserTypeId=' + data.UserTypeId)
  }
}

class UserCreateRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('Users/Create')
  }

  setParams(data) {
    super.setRequestParamDataObj(data)
  }
}

class CreateUserByAdmin extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('Users/CreateUserByAdmin')
  }

  setParams(data) {
    super.setFormData(data)
  }
}

class UsersGetAllRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag('Users/GetUsers')

  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class UsersGeByTokenRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('Users/GetByToken')
  }

}

class UsersUpdateByAdminRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag('Users/UpdateUserByAdmin')
  }

  setQuery(data){
    super.setRequestParam(data)
  }

  setParams(data) {
    super.setFormData(data)
  }
}
class ChangePasswordByAdmin extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('PUT')
    super.setTag('Users/UpdateUserPasswordByAdmin')
  }

  setParams(data){
    super.setRequestParam(data)
  }


}

class UserTypeGetAllRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('UserType/GetAll')
  }
}

class UsersGetRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setMethod('GET')
    super.setTag(`Users/GetUserBy`)

  }

  setParams(data) {
    super.setRequestParam(data)
  }
}

class UsersGetUnreadCountRequest extends WebServiceRequest {
  constructor(context) {
    super(context)
    super.setTag('Users/GetUnreadCount')
  }
}

export {
  UserCreateRequest,
  UsersGetAllRequest,
  UsersUpdateByAdminRequest,
  UsersGetRequest,
  UsersGetUnreadCountRequest,
  CreateUserByAdmin,
  ChangePasswordByAdmin
}
